<template>
    <div>Menu JJK works!</div>
</template>
<script lang="ts">

export default {
  name: 'extensionMenu',
  components: { }
}

</script>